export const heroSection__data = {
  mobileMaxScreenSize: 900,
  paddingY: 10,
  paddingX: 10,

  textOnImage: false,

  imageProperties: {
    image:
      "https://images.leadconnectorhq.com/image/f_webp/q_80/r_768/u_https://assets.cdn.filesafe.space/904NEVFZATeEUX4WRLPy/media/654a9f98a7c5f4297440f8e7.jpeg",
    imageBrightness: 0.9,

    isCirlce: true,
    borderRadius: 20,
  },

  textProperties: {
    title: "We transform spaces with experience and creativity...",
    titleFontSize: 22,
    titlefontWeight: 500,

    subtitle:
      "We talk about the client's desire to create a home they love. We also offer easy to install and maintain solutions in flooring, drywall and paint, which becomes a valuable selling point for our customers.",
    subtitleFontSize: 12,
    subtitlefontWeight: 400,
  },

  linkButton: {
    displayLinkBtn: true,

    url: "https://app.gethearth.com/financing/42915/74109?utm_campaign=42915&utm_content=darkblue&utm_medium=contractor-website&utm_source=contractor&utm_term=74109",
    isDifferentPage: true,

    isImage: true,
    linkPosition: "left",

    paddingY: 0,
    paddingX: 0,

    borderRadius: 3,

    marginTop: 20,

    imageStyles: {
      height: "auto",
      width: "95%",
      aspectRatio: "19/3",
    },

    btnStyles: {
      title: "Get my rates!!",
      backgroundColor: "#0056BC",
      textColor: "white",
      fontSize: 20,
      fontWeight: 400,
    },
  },
};
