// Datos de la seccion Services aqui se puede agregar o cambiar las cards de Custom Epoxy Floors

export const ITC_customEpoxyFloors = {
  title: "Custom Epoxy Floors",
  subtitle: "",

  flipDirection: false,

  marginTop: 50,

  containers: [
    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/904NEVFZATeEUX4WRLPy/media/654aa969a7c5f40f544119bd.jpeg",

      title: "",
      description:
        "Create and install custom-designed floor inlays, medallions, and decorative borders made from various flooring materials, adding artistic and unique elements to the floor.",
    },
    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/904NEVFZATeEUX4WRLPy/media/654ab01578bbac8b02d4e01a.jpeg",

      title: "",
      description:
        "Epoxy is known for its strength, making it an ideal choice for high-traffic areas. But it's not just about durability; Our experts transform epoxy into a canvas for your home or business. With a wide range of colors, effects and finishes, we can tailor each epoxy resin floor to your specific tastes and needs.",
    },
    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/904NEVFZATeEUX4WRLPy/media/654ab1da78bbac3bd2d4e165.jpeg",

      title: "",
      description:
        "Whether you want a shiny, modern finish for your home or a durable industrial floor for your business, our epoxy floors are the perfect solution. Experience the difference that elegance and durability can make in every step you take.",
    },
  ],
};

export const ITC_drywall = {
  title: "Drywall: ",
  subtitle: "the solid foundation for your spaces",
  marginTop: 50,

  flipDirection: true,

  containers: [
    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/904NEVFZATeEUX4WRLPy/media/654ab3af7b3caadaa789826e.jpeg",

      title: "",
      description:
        "Drywall is the solid foundation on which we build environments that reflect your vision and style. At EPOXY DRYWALL & PAINT, we understand the importance of a solid foundation, and drywall is essential in creating a perfect canvas for your home or business.",
    },
    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/904NEVFZATeEUX4WRLPy/media/654ab43e7b3caa917b898365.jpeg",
      title: "",
      description:
        "Our drywall installation professionals are experts in their craft, ensuring uniform, high-quality surfaces. Whether you're building from scratch or renovating an existing space, drywall provides the versatility needed to shape your design ideas.",
    },
    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/904NEVFZATeEUX4WRLPy/media/654ab49e92b8576b9e96a228.jpeg",
      title: "",
      description:
        "From creating functional and efficient partitions to achieving smooth, seamless finishes, drywall is an essential solution for any project. With EPOXY DRYWALL & PAINT, you can trust that you will get a solid foundation on which to build your dreams.",
    },
  ],
};

export const ITC_painting = {
  title: "Paint: ",
  subtitle: "Transform your spaces with our painting services",
  marginTop: 50,

  flipDirection: false,

  containers: [
    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/904NEVFZATeEUX4WRLPy/media/654ab837dabf984bad66bc3d.jpeg",

      title: "",
      description:
        "At EPOXY DRYWALL & PAINT, we believe in the power of paint to transform spaces into authentic works of art. Our painting services go beyond simply applying colors to walls; It's about giving life to your ideas, injecting personality and warmth into every corner.",
    },
    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/904NEVFZATeEUX4WRLPy/media/654ab91adabf984f0866bd50.jpeg",

      title: "",
      description:
        "It's not just about applying the paint; We also provide advice on the selection of colors, finishes and techniques that adapt to your preferences and needs. We care about the details, the proper preparation of surfaces and achieving impeccable finishes.",
    },
    {
      image:
        "https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/904NEVFZATeEUX4WRLPy/media/654abf54dabf9825bf66c878.jpeg",

      title: "",
      description:
        "Discover how our paint can bring your spaces to life, transforming any room into a place that reflects your style and personality. With EPOXY DRYWALL & PAINT, painting is much more than a simple act; It is an artistic expression that improves the beauty and comfort of your home or business.",
    },
  ],
};
