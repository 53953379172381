import React from "react";

import "./ReviewCard.scss";

import DefaultProfilePic from "../../../../defaultStuff/defaultImages/default-profile-icon.jpg";

function ReviewCard({ data }) {
  const { username, userPhoto, stars, location, reviewText } = data;

  return (
    <div className="reviewCard">
      <div className="topSection">
        <div className="photoContainer">
          <img
            src={userPhoto ? userPhoto : DefaultProfilePic}
            alt=""
            className="profilePicture"
            draggable="false"
          />
        </div>
        <div className="userInfoContainer">
          <div className="username">{username}</div>
          {location && (
            <div className="location">
              <i class="fa-solid fa-location-dot"></i> {location}
            </div>
          )}
          <div className="starReviews">
            <span>
              <i
                className={
                  stars >= 1
                    ? "fa-solid fa-star"
                    : stars >= 0.5
                    ? "fa-solid fa-star-half-stroke"
                    : "fa-regular fa-star"
                }
              ></i>
            </span>
            <span>
              <i
                className={
                  stars >= 2
                    ? "fa-solid fa-star"
                    : stars >= 1.5
                    ? "fa-solid fa-star-half-stroke"
                    : "fa-regular fa-star"
                }
              ></i>
            </span>
            <span>
              <i
                className={
                  stars >= 3
                    ? "fa-solid fa-star"
                    : stars >= 2.5
                    ? "fa-solid fa-star-half-stroke"
                    : "fa-regular fa-star"
                }
              ></i>
            </span>
            <span>
              <i
                className={
                  stars >= 4
                    ? "fa-solid fa-star"
                    : stars >= 3.5
                    ? "fa-solid fa-star-half-stroke"
                    : "fa-regular fa-star"
                }
              ></i>
            </span>
            <span>
              <i
                className={
                  stars >= 5
                    ? "fa-solid fa-star"
                    : stars >= 4.5
                    ? "fa-solid fa-star-half-stroke"
                    : "fa-regular fa-star"
                }
              ></i>
            </span>
          </div>
        </div>
      </div>
      <div className="reviewContainer">{reviewText}</div>
    </div>
  );
}

export default ReviewCard;
