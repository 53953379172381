import React, { useEffect, useState } from "react";

import "./GiammariaCard.scss";

function GiammariaCard({ card, titleFontSize }) {
  const [textColor, setTextColor] = useState("");

  useEffect(() => {
    // Function to calculate the brightness of a color
    if (card.backgroundColor) {
      function getBrightness(color) {
        // Convert color to RGB
        const rgb = parseInt(color.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;

        const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
        return brightness;
      }

      const backgroundColorBrightness = getBrightness(card.backgroundColor);

      if (backgroundColorBrightness >= 128) {
        setTextColor("black");
      } else {
        setTextColor("white");
      }
    } else {
      setTextColor("black");
    }
  }, [card.backgroundColor]);

  return (
    <div
      className="giammariaCard"
      style={{ backgroundColor: card.backgroundColor, color: textColor }}
    >
      <div className="infoContainer">
        <div className="title" style={{ fontSize: titleFontSize }}>
          {card.title}
        </div>
        <div className="category">{card.category}</div>
        <div className="description">{card.description}</div>
      </div>

      <div className="imageContainer">
        <img
          src={card.image}
          alt=""
          draggable="false"
          className="giammariaCardImage"
        />
      </div>
    </div>
  );
}

export default GiammariaCard;
