import React from "react";

import "./AboutPage.scss";

import PantherImg from "../myFolder/myImages/panther-logo.webp";

function AboutPage() {
  return (
    <div className="aboutPage">
      <div className="titleAndDescriptionContainer">
        <h1 className="sectionTitle">About Us</h1>

        <div className="sectionDescription">
          At EPOXY DRYWALL & PAINT we are passionate about spaces. We believe
          that the right floors, walls and paint can transform an environment,
          making it more attractive, stylish and functional. That's why we offer
          a wide selection of high-quality flooring options, as well as drywall
          solutions and a varied color palette for your painting projects,
          giving you a complete transformation to your space.
        </div>
      </div>

      <div className="titleAndDescriptionContainer">
        <h1 className="sectionTitle">Our Team</h1>
        <h3 className="sectionSubtitle">
          Dedication and Professionalism in Each Project
        </h3>

        <div className="sectionDescription">
          Each member of our team brings extensive experience in their field,
          allowing us to tackle projects of any size and complexity with
          confidence. From our epoxy floor specialists who create flawless
          surfaces, our drywall experts who transform spaces with skill and
          precision, and our talented painters who bring your ideas to life, our
          team works in harmony to make your projects a reality.
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
