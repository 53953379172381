// Datos de barra de navegacion se encargar de agregar o borrar los enlaces al igual de cambiar los logos
import { websiteName } from "../../constants/constants_info";
import { websiteLogo } from "../../constants/constants_images";

export const navbar__data = {
  pageLinks: [
    {
      text: "Home",
      url: "/",
      isDifferentPage: false,
      openNewTab: false,
    },
    {
      text: "About",
      url: "/about",
      isDifferentPage: false,
      openNewTab: false,
    },
    {
      text: "Services",
      url: "/services",
      isDifferentPage: false,
      openNewTab: false,
    },
    {
      text: "Contact Us",
      url: "/contact",
      isDifferentPage: false,
      openNewTab: false,
    },
  ],

  navbarStickyOrStatic: "static",
  navbarDirection: "vertical",
  gapBetweenLogoAndLinks: 0,
  navbarJustifyContent: "space-between",

  heightAndPadding: {
    navbarHeight: null,
    paddingY: 3,
    paddingX: 10,
  },

  mainColors: {
    backgroundColor: "white",
    textColor: "black",
    linkClickedOrHoveredColor: "#5D91C9",

    hamburgerColor: "#3741A5",
  },

  logoImage: {
    image: websiteLogo,
    imageHeight: 130,
  },

  linksStyles: {
    gapBetweenLinks: 30,

    fontSize: 18,
    fontWeight: 400,
    paddingY: 0,
    paddingX: 3,
    borderRadius: 0,

    linkHoverEffect: "underline",
    linkHoverEffectUnderlineEffectHeight: 3,
  },

  mobileStyles: {
    mobileMaxScreenSize: 820,
    mobileNavbarType: "hamburger",

    mobileNavbarStyles: {
      logoImageHeight: 80,
      linksFontSize: 16,

      hamburgerPosition: 5, // From Right
      hamburgerSize: 42,
    },

    mobileSidebarStyles: {
      titleText: websiteName,
    },
  },
};

// ------------------------------------------------
// ------------------------------------------------
// Stuff I'm not using anymore, but still might use
// ------------ SO DONT DELETE ANYTHING -----------
// ------------------------------------------------
// ------------------------------------------------

// Title
// titleText: websiteName,
// titleFontSize: 22,
// titleFontWeight: 500,
