import React from "react";

import "./CenterItemsContainer.scss";

function CenterItemsContainer(props) {
  const { backgroundImage, marginTop, marginBottom } = props;

  return (
    <div
      className="centerItemsContainer"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        marginTop: marginTop,
        marginBottom: marginBottom,
      }}
    >
      {props.children}
    </div>
  );
}

export default CenterItemsContainer;
