import React, { useEffect, useState } from "react";

import "./Card__PlayingCard.scss";

function Card__PlayingCard({ card, titleFontSize }) {
  const [cardClicked, setCardClicked] = useState(false);

  const [textColor, setTextColor] = useState("");

  useEffect(() => {
    // Function to calculate the brightness of a color
    if (card.backgroundColor) {
      function getBrightness(color) {
        // Convert color to RGB
        const rgb = parseInt(color.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;

        const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
        return brightness;
      }

      const backgroundColorBrightness = getBrightness(card.backgroundColor);

      if (backgroundColorBrightness >= 128) {
        setTextColor("black");
      } else {
        setTextColor("white");
      }
    } else {
      setTextColor("black");
    }
  }, [card.backgroundColor]);

  return (
    <div className="card__playingCard__wrapper">
      <div
        className="card__playingCard"
        onClick={() => setCardClicked(!cardClicked)}
      >
        <div className="contentWrapper">
          <div className="imageContainer">
            <img
              src={card.image}
              alt=""
              draggable="false"
              className="backgroundImage"
            />
          </div>

          <div className="infoContainer">
            <div
              className={`title ${
                card.description && "title__descriptionActive"
              }`}
              style={{
                fontSize: titleFontSize,
                backgroundColor: card.backgroundColor,
                color: textColor,
              }}
            >
              {card.title}
            </div>
            <div className="descriptionContainer">
              <div className="description">{card.description}</div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`card__playingCard__clicked ${
          cardClicked
            ? "card__playingCard__clicked__active"
            : "card__playingCard__clicked__disabled"
        }`}
        onClick={() => setCardClicked(!cardClicked)}
      >
        <div className="contentWrapper">
          <div className="infoContainer">
            <div
              className={`title ${
                card.description && "title__descriptionActive"
              }`}
              style={{
                fontSize: titleFontSize,
              }}
            >
              {card.title}
            </div>
            <div className="descriptionContainer">
              <div className="description">{card.description}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card__PlayingCard;
