import React from "react";

import "./Card__TextOnImageSquared.scss";

function Card__TextOnImageSquared({ card, titleFontSize, sizeOfCard }) {
  return (
    <div className="card__TextOnImageSquared" style={{ height: sizeOfCard }}>
      <div className="imageContainer">
        <img src={card.image} alt="" draggable="false" />
      </div>

      <div className="infoContainer">
        {card.title === "" && card.description === "" ? null : (
          <div className="adjustForPadding">
            <div className="title" style={{ fontSize: titleFontSize }}>
              {card.title}
            </div>
            <div className="description">{card.description}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Card__TextOnImageSquared;
