import React from "react";
import { Link } from "react-router-dom";

import { data_404Page } from "../../myFolder/editComponents/other/data_404Page";

import "./PageNotFound.scss";

function PageNotFound() {
  const { mainTextColor, buttonBgColor, buttonTextColor } = data_404Page;

  return (
    <div className="pageNotFound">
      <div className="mainContentContainer" style={{ color: mainTextColor }}>
        <div className="numbersText">404</div>
        <div className="pageNotFoundText">Page not Found</div>
      </div>

      <Link
        to="/"
        className="returnLink"
        style={{ backgroundColor: buttonBgColor, color: buttonTextColor }}
      >
        Return to home
      </Link>
    </div>
  );
}

export default PageNotFound;
