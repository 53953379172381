import React from "react";

import "./ImageAndTextContainer.scss";

function ImageAndTextContainer({ data }) {
  return (
    <div
      className={`imageAndTextContainerWrapper imageAndTextContainerWrapper__${
        data.flipDirection ? "true" : "false"
      }`}
      style={{ marginTop: data.marginTop }}
    >
      <div className="titleAndSubtitleContainer">
        <div className="title">{data.title}</div>
        {data.subtitle && <div className="subtitle">{data.subtitle}</div>}
      </div>

      {data.containers.map((container, index) => {
        return (
          <div className="imageAndTextContainer" key={index}>
            <div className="imageContainer">
              <img
                src={container.image}
                alt="image"
                draggable="false"
                className="containerImage"
              />
            </div>

            <div className="infoContainer">
              <div className="adjustForPadding">
                <div className="textContainerTitle">{container.title}</div>

                <div className="description">
                  <p>{container.description}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ImageAndTextContainer;
