import React from "react";

import CenterItemsContainer from "../preMadeComponents/containers/CenterItemsContainer/CenterItemsContainer";
import ContactForm from "../preMadeComponents/forms/ContactForm/ContactForm";
import Footer from "../preMadeComponents/otherComponents/Footer/Footer";

function ContactPage() {
  return (
    <div className="contactPage">
      <CenterItemsContainer
      //  backgroundImage={BlueEpoxyImg}
      >
        <ContactForm />
      </CenterItemsContainer>

      <Footer />
    </div>
  );
}

export default ContactPage;
