import React, { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";

import { heroSection__data } from "../../../myFolder/editComponents/other/HeroSectionData";

import styled from "styled-components";
import "./HeroSection.scss";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function HeroSection() {
  const [screenWidth, screenHeight] = useWindowSize();

  const {
    paddingY,
    paddingX,
    mobileMaxScreenSize,
    textOnImage,
    imageProperties,
    textProperties,
    linkButton,
  } = heroSection__data;

  return (
    <div
      className={`heroSection ${
        textOnImage
          ? "heroSection__textOnImage"
          : screenWidth <= mobileMaxScreenSize
          ? "heroSection__mobile"
          : "heroSection__desktop"
      }`}
      style={{
        padding: `${paddingY}px ${paddingX}px`,
      }}
    >
      <div className="imageContainer">
        <img
          src={imageProperties.image}
          alt="Image"
          draggable="false"
          style={
            imageProperties.isCirlce
              ? {
                  clipPath: "circle()",
                  filter: `brightness(${imageProperties.imageBrightness})`,
                }
              : {
                  borderRadius: imageProperties.borderRadius,
                  filter: `brightness(${imageProperties.imageBrightness})`,
                }
          }
        />
      </div>

      <div className="infoContainer">
        <div
          className="titleText"
          style={{
            fontSize: `calc(${textProperties.titleFontSize}px + 1.5vw)`,
            fontWeight: textProperties.titlefontWeight,
          }}
        >
          {textProperties.title}
        </div>

        <div
          className="subtitleText"
          style={{
            fontSize: `calc(${textProperties.subtitleFontSize}px + 1vw)`,
            fontWeight: `${textProperties.subtitlefontWeight}`,
          }}
        >
          {textProperties.subtitle}
        </div>

        {linkButton.displayLinkBtn && (
          <div
            className="linkButtonCotnainer"
            style={{ justifyContent: linkButton.linkPosition }}
          >
            {linkButton.isDifferentPage ? (
              <>
                {linkButton.isImage ? (
                  <a
                    href={linkButton.url}
                    target="_blank"
                    className="linkBtn__imageContainer"
                    style={{
                      padding: `${linkButton.paddingY}px ${linkButton.paddingX}px`,
                      marginTop: linkButton.marginTop,
                    }}
                  >
                    <img
                      src="https://api.gethearth.com/contractor_images/epoxy-drywall-and-paint/banner.jpg?size_id=700x110&color=darkblue"
                      alt="home_improvement 700x110"
                      className="linkBtn__image"
                      draggable="false"
                      style={{
                        borderRadius: linkButton.borderRadius,
                        height: linkButton.imageStyles.height,
                        width: linkButton.imageStyles.width,
                        aspectRatio: linkButton.imageStyles.aspectRatio,
                      }}
                    />
                  </a>
                ) : (
                  <a
                    href={linkButton.url}
                    style={{
                      backgroundColor: linkButton.backgroundColor,
                      color: linkButton.textColor,
                      fontSize: linkButton.fontSize,
                      fontWeight: linkButton.fontWeight,
                      padding: `${linkButton.paddingY}px ${linkButton.paddingX}px`,
                      borderRadius: linkButton.borderRadius,
                      marginTop: linkButton.marginTop,
                    }}
                  >
                    {linkButton.title}
                  </a>
                )}
              </>
            ) : (
              <>
                <Link
                  className="linkButton"
                  to={linkButton.url}
                  target="_blank"
                  style={{
                    backgroundColor: linkButton.backgroundColor,
                    color: linkButton.textColor,
                    fontSize: linkButton.fontSize,
                    fontWeight: linkButton.fontWeight,
                    padding: `${linkButton.paddingY}px ${linkButton.paddingX}px`,
                    borderRadius: linkButton.borderRadius,
                    marginTop: linkButton.marginTop,
                  }}
                >
                  {linkButton.title}
                </Link>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default HeroSection;
