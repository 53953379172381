import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

import styled from "styled-components";
import "./ContactForm.scss";

import { contactForm__data } from "../../../myFolder/editComponents/other/formsData";

import RandomImg from "../../../myFolder/myImages/pricing.jpg";
import ImageCard from "../../cards/ImageCard/ImageCard";
function ContactForm() {
  // IMPORTANT NOTE!!
  // THIS COMPONENT DOESNT WORK YET FOR SENDING PHOTOS

  const {
    inputs,
    inputs_sec2,
    inputStyles,
    formTitle,
    formDescription,
    imageProperties,
  } = contactForm__data;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);

  const form = useRef();

  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);

    emailjs
      .sendForm(
        "service_flc019q",
        "template_eyk8lz7",
        form.current,
        "ilE4sdspsCYdfvV2l"
      )
      .then(
        (result) => {
          setStateMessage("Message sent!!");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000);
        },
        (error) => {
          setStateMessage("Something went wrong, please try again");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000);
        }
      );

    e.target.reset();
  };

  return (
    <div className="contactForm">
      <div className="imageSection">
        {/* <ImageCard
          src={RandomImg}
          width={"95%"}
          height={"35%"}
          maxHeight={400}
          maxWidth={400}
          borderRadius={7}
          border={"1px solid gray"}
        /> */}
      </div>

      <div className="contentSectionWrapper">
        <div className="contentSection">
          <div className="topSection">
            <div className="formTitle">{formTitle}</div>
            <div className="formDescription">{formDescription}</div>
          </div>

          <form onSubmit={sendEmail} className="inputsForm" ref={form}>
            {inputs.map((input, index) => {
              const StyledInputTag = styled.input`
                &:focus {
                  border-bottom: 1px solid ${(props) => props.inputBorderColor};
                }
              `;

              return (
                <div className="inputContainer" key={index}>
                  <div className="inputTitle">
                    {input.title} {input.required && "*"}
                  </div>
                  <StyledInputTag
                    type={input.type}
                    placeholder={input.placeholder}
                    name={input.name}
                    required={input.required}
                    multiple={input.multiple}
                    accept={input.accept}
                    inputBorderColor={inputStyles.inputBorderColor}
                  />
                </div>
              );
            })}
            <br />
            <h4>
              Please tell us a little bit more about your project. We want to
              make sure you found a color of flake epoxy you like
            </h4>

            {inputs_sec2.map((input, index) => {
              const StyledInputTag = styled.input`
                &:focus {
                  border-bottom: 1px solid ${(props) => props.inputBorderColor};
                }
              `;

              return (
                <div className="inputContainer" key={index}>
                  <div className="inputTitle">
                    {input.title} {input.required && "*"}
                  </div>
                  <StyledInputTag
                    type={input.type}
                    placeholder={input.placeholder}
                    name={input.name}
                    required={input.required}
                    multiple={input.multiple}
                    accept={input.accept}
                    inputBorderColor={inputStyles.inputBorderColor}
                  />
                </div>
              );
            })}

            <div className="inputContainer">
              <div className="inputTitle">
                Send pictures, sketches or drawings through email or whatsapp by
                clicking the icons below. <br />
                It's helpful but not necessary to include pictures of the area
                where you are considering our concrete products.
              </div>
              <div className="contactIconsContainer">
                <Link
                  onClick={(e) => {
                    window.location.href =
                      "mailto:epoxy.drywall.andpaint@gmail.com?subject=Here are attached some pictures about my project";
                    e.preventDefault();
                  }}
                >
                  <i class="fa-regular fa-envelope"></i>
                </Link>

                <Link
                  to="https://api.whatsapp.com/send/?phone=%2B14069462673"
                  target="_blank"
                >
                  <i class="fa-brands fa-whatsapp"></i>
                </Link>
              </div>
            </div>

            {stateMessage && <h2>{stateMessage}</h2>}

            <button
              type="submit"
              className="submitButton"
              disabled={isSubmitting}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;

const imageCompression = (file, maxSize = 1024, quality = 0.8) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject("A file was not provided");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();

      img.onload = () => {
        let height = img.height;
        let width = img.width;

        if (height > maxSize || height > maxSize) {
          if (width > height) {
            height *= maxSize / width;
            width = maxSize;
          } else {
            width *= maxSize / height;
            height = maxSize;
          }
        }

        const canvas = document.createElement("canvas");

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");

        const outputFormat = file.type;

        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              const compressedFile = new File([blob], file.name, {
                type: outputFormat,
                lastModified: Date.now(),
              });

              resolve(compressedFile);
            } else {
              reject("Compression failed");
            }
          },
          outputFormat,
          quality
        );
      };

      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  });
};
