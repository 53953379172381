import React, { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";

import { navbar__data } from "../../../myFolder/editComponents/navigation/navbarData";

import styled from "styled-components";
import "./Navbar.scss";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const currentPageUrl = (pageUrl) => {
  const currentPage = window.location.href;

  const localhost = `http://localhost:3000`;
  const networkServer = `http://192.168.2.120:3000`;
  const livePageUrl = `https://demo-page-1.netlify.app`;

  if (currentPage === `${localhost}${pageUrl}`) {
    return "pageLink activePageLink";
  } else if (currentPage === `${networkServer}${pageUrl}`) {
    return "pageLink activePageLink";
  } else if (currentPage === `${livePageUrl}${pageUrl}`) {
    return "pageLink activePageLink";
  } else {
    return "pageLink";
  }
};

function Navbar() {
  const [screenWidth, screenHeight] = useWindowSize();

  if (screenHeight < 5) {
    console.log(
      // Read Comment
      "This is just to avoid getting a warning for not using that variable"
    );
  }

  const [sidebarActive, setSidebarActive] = useState(false);

  const {
    pageLinks,

    navbarStickyOrInline,
    navbarDirection,
    gapBetweenLogoAndLinks,
    navbarJustifyContent,

    heightAndPadding,
    mainColors,
    logoImage,
    linksStyles,
    mobileStyles,

    // Title Text
    titleText,
    titleFontSize,
    titleFontWeight,
  } = navbar__data;

  return (
    <div
      className={`navbar ${
        screenWidth <= mobileStyles.mobileMaxScreenSize
          ? `navbar__mobile__${mobileStyles.mobileNavbarType}`
          : `navbar__${navbarDirection}`
      } `}
      style={{
        position: navbarStickyOrInline,
        height: heightAndPadding.navbarHeight,
        padding: `${heightAndPadding.paddingY}px ${heightAndPadding.paddingX}px`,
        justifyContent: navbarJustifyContent,
        backgroundColor: mainColors.backgroundColor,
        color: mainColors.textColor,
        gap: gapBetweenLogoAndLinks,
      }}
    >
      <Link
        to="/"
        className="logoAndWebsiteName"
        style={{ color: mainColors.textColor }}
      >
        {logoImage.image && (
          <img
            src={logoImage.image}
            alt={``}
            className="navbarLogo"
            draggable="false"
            style={
              screenWidth <= mobileStyles.mobileMaxScreenSize
                ? { height: mobileStyles.mobileNavbarStyles.logoImageHeight }
                : { height: logoImage.imageHeight }
            }
          />
        )}

        {titleText && (
          <div
            className="websiteNameContainer"
            style={{ fontSize: titleFontSize, fontWeight: titleFontWeight }}
          >
            {titleText}
          </div>
        )}
      </Link>

      <div
        className="navbarLinksContainer"
        style={{ gap: linksStyles.gapBetweenLinks }}
      >
        {pageLinks.map((pageLink, index) => {
          const _className = `pageLink pageLink__hover__${linksStyles.linkHoverEffect}`;

          const StyledATag = styled.a`
            color: ${(props) => props.textColor};
            font-size: ${(props) => props.linksFontSize}px;
            font-weight: ${(props) => props.linksFontWeight};
            padding: ${(props) =>
              `${props.linksPaddingY}px ${props.linksPaddingX}px`};
            border-radius: ${(props) => `${props.linksBorderRadius}px`};

            &.pageLink__hover__background:hover {
              background-color: ${(props) => props.linkHoverEffectColor};
            }

            &.pageLink__hover__underline {
              border-bottom: ${(props) =>
                  props.linkHoverEffectUnderlineEffectHeight}px
                solid transparent;
            }

            &.pageLink__hover__underline:hover {
              border-color: ${(props) => props.linkHoverEffectColor};
            }

            &.pageLink__hover__textColor:hover {
              color: ${(props) => props.linkHoverEffectColor};
            }
          `;

          const StyledLinkTag = styled(Link)`
            color: ${(props) => props.textColor};
            font-size: ${(props) => props.linksFontSize}px;
            font-weight: ${(props) => props.linksFontWeight};
            padding: ${(props) =>
              `${props.linksPaddingY}px ${props.linksPaddingX}px`};
            border-radius: ${(props) => `${props.linksBorderRadius}px`};

            &.pageLink__hover__background:hover {
              background-color: ${(props) => props.linkHoverEffectColor};
            }

            &.pageLink__hover__underline {
              border-bottom: ${(props) =>
                  props.linkHoverEffectUnderlineEffectHeight}px
                solid transparent;
            }

            &.pageLink__hover__underline:hover {
              border-color: ${(props) => props.linkHoverEffectColor};
            }

            &.pageLink__hover__textColor:hover {
              color: ${(props) => props.linkHoverEffectColor};
            }
          `;

          return (
            <>
              {pageLink.isDifferentPage ? (
                <StyledATag
                  href={pageLink.url}
                  className={_className}
                  key={index}
                  target={pageLink.openNewTab ? "_blank" : "_self"}
                  // styles
                  textColor={mainColors.textColor}
                  linksFontSize={
                    screenWidth <= pageLinks.mobileMaxScreenSize
                      ? pageLinks.mobileNavbarStyles.linksFontSize
                      : linksStyles.fontSize
                  }
                  linksFontWeight={linksStyles.fontWeight}
                  linksPaddingY={linksStyles.paddingY}
                  linksPaddingX={linksStyles.linksPaddingX}
                  linksBorderRadius={linksStyles.borderRadius}
                  linkHoverEffectColor={mainColors.linkClickedOrHoveredColor}
                  linkHoverEffectUnderlineEffectHeight={
                    linksStyles.linkHoverEffectUnderlineEffectHeight
                  }
                >
                  {pageLink.text}
                </StyledATag>
              ) : (
                <StyledLinkTag
                  to={pageLink.url}
                  className={_className}
                  key={index}
                  // styles
                  textColor={mainColors.textColor}
                  linksFontSize={
                    screenWidth <= mobileStyles.mobileMaxScreenSize
                      ? mobileStyles.linksFontSize
                      : linksStyles.fontSize
                  }
                  linksFontWeight={linksStyles.fontWeight}
                  linksPaddingY={linksStyles.paddingY}
                  linksPaddingX={linksStyles.paddingX}
                  linksBorderRadius={linksStyles.borderRadius}
                  linkHoverEffectColor={mainColors.linkClickedOrHoveredColor}
                  linkHoverEffectUnderlineEffectHeight={
                    linksStyles.linkHoverEffectUnderlineEffectHeight
                  }
                >
                  {pageLink.text}
                </StyledLinkTag>
              )}
            </>
          );
        })}
      </div>

      {mobileStyles.mobileNavbarType === "hamburger" && (
        <>
          <div
            className="hamburgerContainer"
            onClick={() => setSidebarActive(true)}
            style={{
              right: `${mobileStyles.mobileNavbarStyles.hamburgerPosition}px`,
            }}
          >
            <span
              className="material-symbols-outlined"
              style={{
                color: mainColors.hamburgerColor,
                fontSize: mobileStyles.mobileNavbarStyles.hamburgerSize,
              }}
            >
              menu
            </span>
          </div>

          <div
            className={`sidebarContainer  sidebarContainer__${
              sidebarActive ? "active" : "disabled"
            } transparentWhenPageLoads`}
          >
            <div
              className="background"
              onClick={() => setSidebarActive(false)}
            ></div>

            <div className="sidebar">
              <div className="sidebarTitleContainer">
                {mobileStyles.mobileSidebarStyles.titleText}
              </div>

              <div className="sidebarLinksContainer">
                {pageLinks.map((pageLink, index) => {
                  const StyledLinkTag = styled(Link)`
                    &.activePageLink {
                      background-color: ${(props) =>
                        props.selectedBackgroundColor};
                    }
                  `;

                  return (
                    <StyledLinkTag
                      to={`${pageLink.url}`}
                      className={currentPageUrl(pageLink.url)}
                      key={index}
                      onClick={() => setSidebarActive(false)}
                      selectedBackgroundColor={
                        mainColors.linkClickedOrHoveredColor
                      }
                    >
                      {pageLink.text}
                    </StyledLinkTag>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Navbar;
