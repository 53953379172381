import React from "react";

import "./CardsContainer.scss";

function CardsContainer(props) {
  return (
    <div
      className={
        props.scroll ? `cardsContainer__scroll` : "cardsContainer__wrap"
      }
      style={{ marginTop: props.marginTop, marginBottom: props.marginBottom }}
    >
      <div className="titleContainer">{props.title}</div>
      <div className="cardsContainerWrapper">
        <div className="cardsContainer" style={{ gap: props.gapBetween }}>
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default CardsContainer;
