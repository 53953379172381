import React from "react";

import Card__PlayingCard from "./otherCards/Card__PlayingCard/Card__PlayingCard";
import Card__TextOnImageSquared from "./otherCards/Card__TextOnImageSquared/Card__TextOnImageSquared";
import GiammariaCard from "./otherCards/GiammariaCard/GiammariaCard";

function AllCards({ card, card__data }) {
  const {
    cardType,
    cardType__playingCard,
    cardType__textOnImageSquared,
    cardType__giammariaCard,
  } = card__data;

  return (
    <>
      {cardType === "textOnImageSquared" ? (
        <Card__TextOnImageSquared
          card={card}
          titleFontSize={cardType__textOnImageSquared.titleFontSize}
          sizeOfCard={cardType__textOnImageSquared.size}
        />
      ) : cardType === "playingCard" ? (
        <Card__PlayingCard
          card={card}
          titleFontSize={cardType__playingCard.titleFontSize}
        />
      ) : cardType === "giammariaCard" ? (
        <GiammariaCard
          card={card}
          titleFontSize={cardType__giammariaCard.titleFontSize}
        />
      ) : null}
    </>
  );
}

export default AllCards;
