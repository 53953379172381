import { HashRouter as Router, Routes, Route } from "react-router-dom";

// Components
import Navbar from "./preMadeComponents/navigation/Navbar/Navbar";

// Pages
import AboutPage from "./myPages/AboutPage";
import ContactPage from "./myPages/ContactPage";
import HomePage from "./myPages/HomePage";
import ServicesPage from "./myPages/ServicesPage";
import PageNotFound from "./preMadeComponents/pageNotFound/PageNotFound";

function App() {
  return (
    <Router>
      <Navbar />

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
