import React from "react";

import "./ImageCard.scss";

function ImageCard({
  src,
  width,
  height,
  maxWidth,
  maxHeight,
  minWidth,
  minHeight,
  aspectRatio,
  borderRadius,
  border,
  boxShadow,
}) {
  return (
    <div
      className="imageCard"
      style={{
        height: height,
        width: width,
        maxHeight: maxHeight,
        maxWidth: maxWidth,
        minHeight: minHeight,
        minWidth: minWidth,
        aspectRatio: aspectRatio,
      }}
    >
      <img
        src={src}
        alt=""
        className="imageCardImg"
        draggable="false"
        style={{
          border: border,
          borderRadius: borderRadius,
          boxShadow: boxShadow,
        }}
      />
    </div>
  );
}

export default ImageCard;
