import React from "react";

// Data
import { serviceCards__data } from "../myFolder/editComponents/cards/cardsData";
import GoogleMap from "../preMadeComponents/helpers/GoogleMap";
import ReviewCard from "../preMadeComponents/cards/reviewCards/ReviewCard/ReviewCard";
import { reviews__data } from "../myFolder/editComponents/cards/data_reviews";

// Images
import RandomImg from "../myFolder/myImages/pricing.jpg";

// Components
import AllCards from "../preMadeComponents/cards/AllCards";
import CardsContainer from "../preMadeComponents/containers/CardsContainer/CardsContainer";
import HeroSection from "../preMadeComponents/otherComponents/HeroSection/HeroSection";
import Footer from "../preMadeComponents/otherComponents/Footer/Footer";
import ImageCard from "../preMadeComponents/cards/ImageCard/ImageCard";
import CenterItemsContainer from "../preMadeComponents/containers/CenterItemsContainer/CenterItemsContainer";

function HomePage() {
  return (
    <div>
      <HeroSection />

      <CardsContainer
        scroll={true}
        marginTop={60}
        gapBetween={10}
        title={"Services"}
        titlePosition={"Center"}
      >
        {serviceCards__data.cards.map((card, index) => {
          return <AllCards card={card} card__data={serviceCards__data} />;
        })}
      </CardsContainer>

      <CardsContainer
        title="Reviews"
        gapBetween={15}
        marginTop={60}
        marginBottom={60}
      >
        {reviews__data.reviews.map((review, index) => {
          return <ReviewCard data={review} key={index} />;
        })}
      </CardsContainer>

      <CenterItemsContainer marginBottom={60}>
        <ImageCard
          src={RandomImg}
          width={"95%"}
          height={"95%"}
          maxHeight={400}
          maxWidth={400}
          borderRadius={7}
          border={"1px solid gray"}
        />
      </CenterItemsContainer>

      <GoogleMap
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d382647.0801410922!2d-111.12823297567046!3d45.53799128573846!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2f627f5a9fe0ec2b%3A0x3ef54e38ea9ebe16!2sEpoxy Drywall %26 Paint!5e0!3m2!1sen!2smx!4v1708451592999!5m2!1sen!2smx"
        height={450}
        width={"100%"}
      />

      <Footer />
    </div>
  );
}

export default HomePage;
